import * as React from "react";
import { Component } from "react";
// import * as PropTypes from "prop-types";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

const styles = createStyles({
    root: {
        flexGrow: 1,
    }
});

class TopBar extends Component {
    auth: any;
    props: any;
    isAuthenticated: any;
    state: any;

    // static propTypes = {
    //     classes: PropTypes.object.isRequired,
    // };

    constructor(props: any) {
        super(props);
        this.isAuthenticated = props.auth.isAuthenticated;
        this.auth = props.auth;
        this.auth.loginCallback = this.loggedIn.bind(this);
        this.auth.logoutCallback = this.loggedOut.bind(this);

        if ("isLoggedIn" in localStorage) {
            this.state = { loggedIn: localStorage.getItem("isLoggedIn") };
        } else {
            this.state = { loggedIn: false };
        }
    }

    loggedIn() {
        this.setState({ loggedIn: true });

        // Load notes from API on login
        
    }

    loggedOut() {
        this.setState({ loggedIn: false });
    }

    render() {
        const login: any = this.props.auth.login;
        const logout: any = this.props.auth.logout;

        return (
            <div className="topbar" style={{ alignContent: "right", width: "100" }}>
                { !this.state.loggedIn && (
                    <button
                        className="btn btn-dark"
                        style={{ backgroundColor: "#252525", fontSize: "10px", margin: "10px" }}
                        onClick={login}
                        title="login to your account">
                        .:. come on in .:.
                    </button>
                )}
                { this.state.loggedIn && (
                    <button
                        className="btn btn-dark"
                        style={{ backgroundColor: "#252525", fontSize: "10px", margin: "10px" }}
                        onClick={logout}
                        title="logout of your account">
                        .:. sign out .:.
                    </button>
                )}
            </div>
        );
    }
}

const topBarStyle: any = withStyles(styles)(TopBar);

export default withRouter(topBarStyle);
