export module MongoConstants {
    export const CREATE_NOTE_SUCCESS: string = "CREATE_NOTE_SUCCESS";
    export const CREATE_NOTE_ERROR: string = "CREATE_NOTE_ERROR";

    export const GET_NOTES_SUCCESS: string = "GET_NOTES_SUCCESS";
    export const GET_NOTES_ERROR: string = "GET_NOTES_ERROR";

    export const UPDATE_USER_SETTING_AUTOSAVE_SUCCESS: string = "UPDATE_USER_SETTING_AUTOSAVE_SUCCESS";
    export const UPDATE_USER_SETTING_AUTOSAVE_ERROR: string = "UPDATE_USER_SETTING_AUTOSAVE_ERROR";
}
