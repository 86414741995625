import React, { Component } from 'react'
import { connect } from 'react-redux'
import QuoteRandom from 'component/layout/elements/QuoteRandom';
import ToDoList from 'component/layout/todo/ToDoList';
import MongoActions from "../../actions/MongoActions";


interface IPageLocationFrontDoorProps {
    auth: any;
}

interface IPageLocationFrontDoorState {
}

class PageLocationFrontDoor extends Component<IPageLocationFrontDoorProps, IPageLocationFrontDoorState> {
    state: any = null;

    constructor(props: any) {
        super(props);
        this.state = {
            isAuthenticated: this.props.auth
        };
    }

    quoteFilterByTags: string[] = ["front-door", "time-am"];

    // todosFilterByTags: string[] = ["front-door"];
    todosFilterByTags: string[] = [];

    todosFilterByTagsEliza: string[] = ["front-door-eliza"];
    todosFilterByTagsWinter: string[] = ["front-door-winter"];
    todosFilterByTagsMama: string[] = ["front-door-mama"];
    todosFilterByTagsSandon: string[] = ["front-door-sandon"];

    noteType: string = "todo";


    render() {
        return (
            <div>
                {/* Display a random quote */}
                <QuoteRandom filterByTags={this.quoteFilterByTags} />

                {/* Section Title */}
                <h3 className="todoList" id="todolist-title">Bloo Bee @ the Door</h3>


                {/* Section Title */}
                <h3 className="todoList" id="todolist-title-sub">for Eliza</h3>
                <ToDoList filterByTags={this.todosFilterByTagsEliza} showDoorOnly={true} />

                {/* Section Title */}
                <h3 className="todoList" id="todolist-title-sub">for Winter</h3>
                <ToDoList filterByTags={this.todosFilterByTagsWinter} showDoorOnly={true} />

                {/* Section Title */}
                <h3 className="todoList" id="todolist-title-sub">for Mama</h3>
                <ToDoList filterByTags={this.todosFilterByTagsMama} showDoorOnly={true} />

                {/* Section Title */}
                <h3 className="todoList" id="todolist-title-sub">for Sandon</h3>
                <ToDoList filterByTags={this.todosFilterByTagsSandon} showDoorOnly={true} />

                {/* Display Starred ToDo Items */}
                {/* <ToDoList /> */}
                {/* <ToDoList starred={true} /> */}

                {/* Display Un-Starred ToDo Items */}
                {/* <ToDoList starred={false} /> */}

                {/* Display the "Create Note" Form */}
                {/* <PageCreateNote noteType={this.noteType} /> */}

            </div>
        )
    }
}
const mapStateToProps = (state: any) => ({
    // state
    notes: state.notes,
});

const mapDispatchToProps = (dispatch: any) => ({
    getNotes: (req: any) => dispatch(MongoActions.getNotes(req.data, req.cb, "todo")),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageLocationFrontDoor);