export module ApiConstants {

    export const _inv: any = {
        DEV: {
            auth0: {
                domain: "hive-point-seven.auth0.com",
                clientID: "plxNHCej610iIwzoFrAKbpwzwW1T9wVI",
                redirectUri: "http://localhost:3000/callback",
                responseType: "token id_token",
                scope: "openid profile email",
            },
            mongo: {
                url:
                    "https://webhooks.mongodb-stitch.com/api/client/v2.0/app/stitch-app-1-cdzgt/service/",
                secret: "gmu4V2Mgmu4V2MCzST5CzST5",
            },
        },
        PRODUCTION: {
            auth0: {
                domain: "hive-point-seven.auth0.com",
                clientID: "plxNHCej610iIwzoFrAKbpwzwW1T9wVI",
                redirectUri: "https://write.hivejournal.com/callback",
                responseType: "token id_token",
                scope: "openid profile email",
            },
            mongo: {
                url:
                    "https://webhooks.mongodb-stitch.com/api/client/v2.0/app/stitch-app-1-cdzgt/service/",
                secret: "gmu4V2Mgmu4V2MCzST5CzST5",
            },
        },
        // ENVIRONMENT: "DEV",
        ENVIRONMENT: "PRODUCTION",
    };

    export function getAuthApi() {
        let data: any = _inv[_inv.ENVIRONMENT].auth0;
        return data;
    };
    export function getMongoApi() {
        let data: any = _inv[_inv.ENVIRONMENT].mongo;
        return data;
    };
}
