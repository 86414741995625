import React, { useContext } from "react";
import { AppContext } from "../../../providers/AppProvider";
import ToggleSwitch from './ToggleSwitch';

const ThemeSwitcher = () => {
    const context = useContext(AppContext) as any;
    const { toggleTheme, themeMode } = context;

    const handleThemeChange = (e: any) => {
        toggleTheme();
    };

    return (
        <ToggleSwitch
            className="toggled"
            checked={themeMode === "darkTheme" ? true : false}
            spanText="Dark theme"
            onChange={handleThemeChange}
            onColor={'#4ec6fe'} />
    );
};

export default ThemeSwitcher;
