import { combineReducers } from "redux";
import AppSessionReducer from "./AppSessionReducer";
import MongoReducer from "./MongoReducer";
import { reducer as FormReducer } from "redux-form";

export default combineReducers({
    app: AppSessionReducer,
    form: FormReducer,
    MongoReducer,
});
