import { createGlobalStyle, css } from "styled-components";

export default createGlobalStyle`
${({ theme }) => css`
        #root {
          background: ${theme.colors.background};
          color: ${theme.colors.color};
          overflow: hidden !important;  
        }
        #form-textarea-control-body {
          color: ${theme.colors.color};
          border-left: ${theme.colors.color};
        }
        #form-fieldarea-control-body 
        {
          color: ${theme.colors.color};
          border-left: ${theme.colors.color};
        }
        #todolist-title
        {
          color: ${theme.colors.headlineColor}; 
        }
        #todolist-title-sub
        {
          color: ${theme.colors.offColor}; 
        }
        .text-info-card span
        {
          color: ${theme.colors.offColor} !important; 
        }
        .text-info-card p    
        {
          color: ${theme.colors.color} !important; 
        }
        .text-info-card-tags span
        {
          color: ${theme.colors.headlineColor} !important; 
        }
        .note-delete-button
        {
          color: ${theme.colors.headlineColor} !important;
        }     
  `}
`;