import { ActionTypes } from "../actions/ActionTypes";

const usersDefaultState: any = [];
const AppSessionReducer = (state = usersDefaultState, action: any) => {
    switch (action.type) {
        case ActionTypes.CREATE_NEW_NOTES:
            return [...state, Object.assign({}, action.appSession)];

        case ActionTypes.NOTES_SUCCESS:
            return [usersDefaultState];
        case ActionTypes.REMOVE_NOTES:
            return state.filter((data: any, i: any) => i !== action.id);
        default:
            return state;
    }
};

export default AppSessionReducer;