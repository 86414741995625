import React from 'react';

type Props = {
    filterByTags?: any
  };

const QuoteRandom = ({
    filterByTags = ["todo"]
}: Props) => {
        const quotes: any = defaultQuotes;

        // --- FILTER BY TAGS ---
        // https://stackoverflow.com/questions/16312528/check-if-an-array-contains-any-element-of-another-array-in-javascript
        const filteredQuotes = quotes.filter(function (el: any) {
            return el.tags.some((v: any) => filterByTags.toString().indexOf(v) !== -1);
          });
        const randomQuote: any = filteredQuotes[Math.floor(Math.random() * filteredQuotes.length)];
 
        return (
            <span style={{
                fontStyle: "italic",
                fontSize: "0.8em",
            }}>
                {randomQuote.quotation}
            </span>
        )
    }
export default QuoteRandom;

const defaultQuotes: { 
    tags: string[], 
    quotation: string, 
    source?: string 
    }[] = [
    { tags: ["todo"], quotation: "A little bit of planning now can erase a lot of stress later."},
    { tags: ["todo"], quotation: "Until we can manage time, we can manage nothing else." },
    { tags: ["front-door", "time-am"], quotation: "'Good Morning! (it's still 'am', right?) :)'--bloo bee" },
    { tags: ["front-door", "time-pm"], quotation: "'Good non-Morning! (it's still 'pm', right?) :)'--bloo bee" },
    { tags: ["journal"], quotation: "JOURNAL quotation 2" },
    { tags: ["journal"], quotation: "JOURNAL quotation 2" },
];

