import React, { Component } from "react";
import { Form, TextArea } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import * as AppSession from "../../actions/AppSession";
import MongoActions from "../../actions/MongoActions";
import Utils from "../../utils/UtilsIndex";
import "../../assets/styles/css/createnote.css";
import { message } from "antd";

interface IPageCreateNoteProps {
    createNote: Function;
    // TODO: Remove AppSession and associated functions if unused
    createNotes: Function;
    deleteNotes: Function;
    auth: any;
    noteType?: string;
}

interface IPageCreateNoteState {
}
class PageCreateNote extends Component<IPageCreateNoteProps, IPageCreateNoteState> {

    state: any = null;
    noteType?: string;

    constructor(props: any) {
        super(props);
        this.state = {
            body: "",
            error: false,
            message: "",
            space: "",
            resetbody: "",
            dots: [],
            settings: null,
            saveOnKeyEnter: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e: any) {
        this.setState({
            body: e.target.value,
        });
    }

    componentWillReceiveProps(nextprops: any) {
        if (nextprops.app) {
            this.setState({
                body: "",
                message: ".:. note saved .:.",
            });
        }
    }

    componentDidMount() {
        let settings = localStorage.getItem("USER_SETTINGS");
        if (settings) {
            this.setState({
                settings: JSON.parse(settings),
            });
        }
    }

    toggleSaveOnKeyEnter = () => {
        this.setState({
            saveOnKeyEnter: !this.state.saveOnKeyEnter,
        });
    };

    saveNote(isAuthenticated: any) {
        let notes: any = [];
        let saved_notes = localStorage.getItem("USER_NOTES");

        if (saved_notes) {
            try {
                console.log(saved_notes);
                notes = JSON.parse(saved_notes);
            } catch (e) {
                console.log(e);
                localStorage.removeItem("USER_NOTES");
            }
        }

        let origText = this.state.body;
        let text = origText.substring(0, origText.length);
        let exp = new RegExp("#[0-9a-z]+s?", "gi");
        let tags = origText.match(exp);

        // let mainTag = "journal";
        let mainTag = this.props.noteType === "todo" ? "todo" : "journal";

        if (tags) {
            tags.forEach((tag: any, i: any) => {
                tags[i] = tag.substr(1);
                if (i === 0) {
                    mainTag = tags[i];
                }
                text = text.replace(tag + " ", "");
            });
            text = text.replace("#" + tags[tags.length - 1], "");
        }

        const { settings } = this.state;
        let note_item = {
            note_orig: origText,
            note_text: text,
            note_tags: tags,
            creation_timestamp: moment().format(),
            entry_ip: "0.0.0.0",
            entry_app_type: "web",
            font: "PremiumUltra26",
            color: "#1d88cc",
            main_tag: mainTag,
            hide: false,
            entry_app_version: "1.0",
            word_count: Utils.getWords(origText),
            permissions: {
                see: settings ? settings.noteVisibility.standard[0] : "any",
                read: settings ? settings.noteVisibility.standard[1] : "invites",
            },
        };
        notes.push(note_item);

        let user_id: any = localStorage.getItem("USER_ID");
        let arg: any = note_item;
        arg.note_orig = Utils.encrypt(arg.note_orig);
        arg.note_text = Utils.encrypt(arg.note_text);
        arg["user_id"] = user_id;
        console.log(arg, note_item, notes);

        this.props.createNote({
            data: arg,
            cb: (res: any) => {
                let loggedIn = isAuthenticated();
                if (!isAuthenticated()) {
                    if (this.props.auth.login()) {
                        loggedIn = true;
                    };
                }
                if (loggedIn) {
                    console.log(res);
                    localStorage.setItem("USER_NOTES", JSON.stringify(notes));

                    let data = {
                        user: {
                            auth_provider: "auth0",
                            user_id_auth: user_id,
                        },
                        notes: notes,
                    };
                    console.log("*** Saved Notes ***");
                    console.log(data);
                    this.props.createNotes(data);
                    message.warn(".:. note saved .:.", 1);
                }
            },
        });
    }

    keyPress(e: any) {
        const { isAuthenticated } = this.props.auth;
        switch (e.keyCode) {
            case 13:
                // Enter
                this.setState({
                    body: e.target.value,
                    dots: [],
                });

                if (this.state.saveOnKeyEnter) {
                    this.saveNote(isAuthenticated);
                }
                break;

            case 32:
                // Space
                let el = document.getElementById("form-textarea-control-body");
                let body = e.target.value;
                let curPos = this.getCaretPosition(el);
                let newPos = curPos;

                if (curPos > 2) {
                    let lastTwo = body.substr(curPos - 2, 2);
                    if (
                        lastTwo === "  " ||
                        (this.state.dots.includes(curPos - 2) === false && lastTwo === ". ")
                    ) {
                        let rids = lastTwo === ". " ? 2 : 1;
                        body = body.substr(0, curPos - rids) + "#" + body.slice(curPos);
                        newPos -= rids;
                    }
                }

                this.setState(
                    (state) => ({
                        ...this.state,
                        body: body,
                    }),
                    () => {
                        if (curPos !== newPos) {
                            this.setCaretPosition(el, newPos + 1);
                        }
                    }
                );
                break;

            case 190:
                // dot
                let dots = this.state.dots;
                dots.push(
                    this.getCaretPosition(
                        document.getElementById("form-textarea-control-body")
                    ) - 1
                );
                this.setState({
                    dots: dots,
                });
                break;

            default:
                break;
        }
    }

    getCaretPosition = (oField: any) => {
        // Initialize
        var iCaretPos = 0;

        // Firefox support
        if (oField.selectionStart || oField.selectionStart === "0")
            iCaretPos =
                oField.selectionDirection === "backward"
                    ? oField.selectionStart
                    : oField.selectionEnd;

        // Return results
        return iCaretPos;
    };

    setCaretPosition = (elem: any, caretPos: any) => {
        if (elem !== null) {
            if (elem.createTextRange) {
                var range = elem.createTextRange();
                range.move("character", caretPos);
                range.select();
            } else {
                if (elem.selectionStart) {
                    elem.focus();
                    elem.setSelectionRange(caretPos, caretPos);
                } else elem.focus();
            }
        }
    };

    listView(data: any, index: any) {
        return (
            <div className="row" key={index}>
                <div className="col-md-10">
                    <li className="list-group-item clearfix">{data.body}</li>
                </div>
                <div className="col-md-2">
                    <button
                        onClick={(e) => this.deleteNotes(e, index)}
                        className="btn btn-danger">
                        Remove
                    </button>
                </div>
            </div>
        );
    }

    deleteNotes(e: any, index: any) {
        e.preventDefault();
        this.props.deleteNotes(index);
    }


    render() {
        return (
            <div>

                {/* Change the CSS class based on the note type */}
                <div className={this.props.noteType === "todo" ? "TextBlockToDoCreate" : "TextBlock"}>

                    <Form error={this.state.error}>
                        <Form.Field
                            name="noteBody"
                            id="form-textarea-control-body"
                            control={TextArea}
                            placeholder="..."
                            value={this.state.body}
                            onChange={this.handleChange}
                            onKeyUp={this.keyPress.bind(this)} />

                        {!this.state.saveOnKeyEnter && (
                            <div className="save-buttons">
                                <button
                                    className="btn btn-dark"
                                    style={{ backgroundColor: "#252525", fontSize: "10px", margin: "10px" }}
                                    onClick={() => this.saveNote(this.props.auth.isAuthenticated)}
                                    title="save new note when button is pressed">
                                    .:. save note .:.
                                </button>
                                <button
                                    className="btn btn-dark"
                                    style={{ backgroundColor: "#252525", fontSize: "10px" }}
                                    onClick={() => this.toggleSaveOnKeyEnter()}
                                    title="save note when enter is pressed">
                                    .:. enable quick-save .:.
                                </button>
                            </div>
                        )}

                        {this.state.saveOnKeyEnter && (
                            <div>
                                <button
                                    className="btn btn-dark"
                                    style={{ backgroundColor: "#252525", fontSize: "10px", margin: "10px" }}
                                    onClick={() => this.toggleSaveOnKeyEnter()}
                                    title="for a new note, use the 'save' button instead of pressing enter">
                                    .:. disable quick-save .:.
                                </button>
                            </div>
                        )}
                    </Form>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        mongoNote: state.MongoReducer.mongoNote,
        error: state.MongoReducer.error,
        app: state.app,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        createNote: (req: any) => dispatch(MongoActions.createNote(req.data, req.cb)),
        // TODO: Remove AppSession and associated functions if unused
        createNotes: (appSession: any) => dispatch(AppSession.createNotes(appSession)),
        deleteNotes: (index: any) => dispatch(AppSession.deleteNotes(index)),
        resetNotes: (appSession: any) => dispatch(AppSession.resetNotes(appSession)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCreateNote);
