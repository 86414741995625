import { MongoConstants } from "../constants/MongoConstants";

const initialState = {
    mongoNote: null,
    mongoGetNotes: null,
    error: null,
    user: {
        generalPreferences: {
            autoSaveEnabled: false
        }
    }
};

function MongoReducer(state = initialState, action: any) {
    switch (action.type) {
        case MongoConstants.CREATE_NOTE_SUCCESS:
            return Object.assign({}, state, {
                mongoNote: action.response,
                error: null,
            });

        case MongoConstants.CREATE_NOTE_ERROR:
            return Object.assign({}, state, {
                mongoNote: {},
                error: action.error,
            });

        case MongoConstants.GET_NOTES_SUCCESS:
            return {
                ...state,
                mongoGetNotes: action.payload
            }

        case MongoConstants.GET_NOTES_ERROR:
            return Object.assign({}, state, {
                error: action.error,
            });

        case MongoConstants.UPDATE_USER_SETTING_AUTOSAVE_SUCCESS:
            return Object.assign({}, state, {
                testUserSettingsAutoSaveActionResponse: action.response,
                error: null,
            });

        case MongoConstants.UPDATE_USER_SETTING_AUTOSAVE_ERROR:
            return Object.assign({}, state, {
                testUserSettingsAutoSaveActionResponse: {},
                error: action.error,
            });

        default:
            return state;
    }
}

export default MongoReducer;
