import React, { Component } from 'react'
import QuoteRandom from 'component/layout/elements/QuoteRandom';
import ToDoList from 'component/layout/todo/ToDoList';
import PageCreateNote from 'component/pages/PageCreateNote';

interface IPageToDoProps {
    auth: any;
}

interface IPageToDoState {
}

class PageToDo extends Component<IPageToDoProps, IPageToDoState> {
    state: any = null;

    constructor(props: any) {
        super(props);
        this.state = {
            isAuthenticated: this.props.auth
        };
    }

    filterByTags: string[] = ["todo"];
    noteType: string = "todo";
    starred: boolean = false;

    render() {
        return (
            <div>
                {/* Display a random quote */}
                <QuoteRandom filterByTags={this.filterByTags} />

                {/* Section Title */}
                <h3
                    className="todoList"
                    id="todolist-title">
                    Today's Focus
                </h3>

                {/* Display ToDos from Mongo API */}
                {/* <ToDoList /> */}

                {/* Display Starred ToDo Items */}
                <ToDoList showStarredOnly={true} />

                {/* Section Title */}
                <h3
                    className="todoList"
                    id="todolist-title">
                    Tomorrow (Woman/Man)
                </h3>

                <hr />
                {/* Display Un-Starred ToDo Items */}
                <ToDoList showUnStarredOnly={true} />

                {/* Display the "Create Note" Form */}
                <PageCreateNote noteType={this.noteType} />

            </div>
        )
    }
}
export default PageToDo;