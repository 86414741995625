import * as auth0 from "auth0-js";
import axios from "axios";
import { ApiConstants } from "../../constants/ApiConstants";

export default class Auth {
  accessToken: any;
  auth0: any;
  history: any;
  idToken: any;
  userProfile: any;
  signOut: any;
  apiConstants: any = ApiConstants;
  loginCallback: any;
  logoutCallback: any;

  constructor(history: any) {
    this.history = history;
    this.userProfile = null;
    this.auth0 = new auth0.WebAuth(this.apiConstants.getAuthApi());
    this.signOut = null;
    this.loginCallback = () => { };
    this.logoutCallback = () => { };
  }

  login = () => {
    this.auth0.authorize();
  };

  handleAuthentication = () => {
    this.auth0.parseHash((err: any, authResult: any) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);

        this.auth0.client.userInfo(
          this.getAccessToken(),
          (err: any, user: any) => {
            localStorage.setItem("USER_ID", user.sub);
            let api = this.apiConstants.getMongoApi();
            let data = user;
            data["user_id"] = user.sub;
            delete data.sub;
            console.log(data);
            axios({
              method: "POST",
              url:
                api.url +
                "service-http-users-create-v2/incoming_webhook/webhook-users-create-v3?secret=" +
                api.secret,
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            })
              .then((response) => {
                console.log(response);
                if (response.data.userSettings) {
                  localStorage.setItem(
                    "USER_SETTINGS",
                    JSON.stringify(
                      response.data.userSettings,
                    ),
                  );
                }
                this.history.replace("/");
              })
              .catch((error) => {
                console.log(error);
              });
          },
        );
      } else if (err) {
        this.history.replace("/");

        alert(
          `Error: ${err.error}. Check the console for further details.`,
        );
        console.log(err);
      }
    });
  };

  getIdToken() {
    return this.idToken;
  }

  setSession = (authResult: any) => {
    localStorage.setItem("isLoggedIn", "true");
    console.log(authResult);

    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 7200 + new Date().getTime(),
    );

    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);

    this.loginCallback({ loggedIn: true });
  };

  renewSession() {
    this.auth0.checkSession({}, (err: any, authResult: any) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        this.logout();
        console.log(err);
        alert(
          `Could not get a new token (${err.error}: ${err.error_description}).`,
        );
      }
    });
  }

  logout = () => {
    localStorage.clear();

    const apiConst = this.apiConstants.getAuthApi();
    this.auth0.logout({
      clientID: apiConst.clientID,
      returnTo: window.location.origin,
    });

    this.logoutCallback({ loggedIn: false });
  };

  isAuthenticated() {
    const expiresAt = localStorage.getItem("expires_at");
    if (!expiresAt) {
      return false;
    }
    return new Date().getTime() < JSON.parse(expiresAt);
  }

  getAccessToken = () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      throw new Error("No access token found.");
    }
    return accessToken;
  };

  getProfile = (cb: any) => {
    if (this.userProfile) return cb(this.userProfile);
    this.auth0.client.userInfo(
      this.getAccessToken(),
      (err: any, profile: any) => {
        if (profile) this.userProfile = profile;
        cb(profile, err);
      },
    );
  };
}
