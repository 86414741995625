// Mongo API call to get todos
import React, { Component } from 'react'
import { connect } from 'react-redux'
import MongoActions from "../../../actions/MongoActions";
import ToDoItem from 'component/layout/todo/ToDoItem';

interface IToDoListProps {
    getNotes: Function;
    filterByTags?: string[];
    showStarredOnly?: boolean;
    showUnStarredOnly?: boolean;
    showDoorOnly?: boolean;
}

interface IToDoListState {
}

class ToDoList extends Component<IToDoListProps, IToDoListState> {
    state: any = null;
    props: any;

    constructor(props: any) {
        super(props);
        this.state = {
            testText: "hello",
            notesTodos: [], // array of todo note objects,
            notesTodosStarred: [],
            notesTodosUnstarred: [],
            notesDoor: [],
            // isAuthenticated: this.props.auth
            mainTag: "todo",
            pageNum: 1,
            pageSize: 25,
        };
    }

    componentDidMount() {
        // console.log(".:.ToDos:componentDidMount");

        // call the mongo API to get the latest todo notes
        let arg: any = {};
        // let showStarredOnly: boolean = this.props.showStarredOnly;
        // let showUnStarredOnly: boolean = this.props.showUnStarredOnly;
        this.props.getNotes({
            data: arg,
            cb: (res: any) => {
                console.log(res);

                // typeof string
                let localStorageNotes = localStorage.getItem("USER_NOTES_MAINTAGS_" + (this.state.mainTag.toString().toUpperCase())
                ) as any;
                // console.log(`typeof notes: ${typeof notes}`);

                // array of note objects
                let _notes: any = [];
                let _notesStarred: any = [];
                let _notesUnStarred: any = [];
                let _notesDoor: any = [];

                if (localStorageNotes) {
                    let note: any = {};
                    let thisNote: any = {};
                    let i = 0;
                    for (note in localStorageNotes) {
                        // Append this note to the array of notes
                        thisNote = JSON.parse(localStorageNotes)[i];
                        _notes.push(thisNote);
                        if (thisNote?.note?.starred) {
                            _notesStarred.push(thisNote);
                        } else {
                            _notesUnStarred.push(thisNote);
                        }
                        i++;
                    }

                    // --- FILTER BY TAGS ---
                    // https://stackoverflow.com/questions/16312528/check-if-an-array-contains-any-element-of-another-array-in-javascript
                    const _doorFilterByTags: string[] = this.props.filterByTags;
                    console.log(`.:._notes: ${_notes}`);

                    let _note_tags: string[] = [];
                    // _notesDoor = _notes.filter(function (el: any) {
                    _notesDoor = _notes.filter(function (el: any) {
                        _note_tags = el?.note?.note_tags ? el.note.note_tags : [];
                        // return _note_tags.some((v: any) => _doorFilterByTags.toString().indexOf(v) !== -1);
                        return _note_tags.some((v: any) => _doorFilterByTags?.toString().indexOf(v) !== -1);
                    });

                    this.setState({
                        notesDoor: _notesDoor,
                        notesTodos: _notes,
                        notesTodosUnstarred: _notesUnStarred,
                        notesTodosStarred: _notesStarred
                    });
                }
            },
            mainTag: this.state.mainTag,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            showStarredOnly: this.props.showStarredOnly,
            showUnStarredOnly: this.props.showStarredOnly,
            showDoorOnly: this.props.showDoorOnly
        });
    }

    render() {
        if (this.props.showStarredOnly) {
            return (
                this.state.notesTodosStarred.map((todo: any) => {
                    // console.log(`.:. todo: ${JSON.stringify(todo)}`);

                    if (todo?._id && todo?.note?.note_orig_decrypted) {
                        return <ToDoItem key={todo._id} todo={todo} noteText={todo.note.note_orig_decrypted} />
                    } else {
                        return <></>
                    }

                })
            )
        } else if (this.props.showUnStarredOnly) {
            return (
                this.state.notesTodosUnstarred.map((todo: any) => {
                    // console.log(`.:. todo: ${JSON.stringify(todo)}`);

                    if (todo?._id && todo?.note?.note_orig_decrypted) {
                        return <ToDoItem key={todo._id} todo={todo} noteText={todo.note.note_orig_decrypted} />
                    } else {
                        return <></>
                    }

                })
            )
        } else if (this.props.showDoorOnly) {
            return (
                this.state.notesDoor.map((todo: any) => {
                    // console.log(`.:. todo: ${JSON.stringify(todo)}`);

                    if (todo?._id && todo?.note?.note_orig_decrypted) {
                        return <ToDoItem key={todo._id} todo={todo} noteText={todo.note.note_orig_decrypted} />
                    } else {
                        return <></>
                    }

                })
            )
        } else {
            return (
                this.state.notesTodos.map((todo: any) => {
                    // console.log(`.:. todo: ${JSON.stringify(todo)}`);

                    if (todo?._id && todo?.note?.note_orig_decrypted) {
                        return <ToDoItem key={todo._id} todo={todo} noteText={todo.note.note_orig_decrypted} />
                    } else {
                        return <></>
                    }

                })
            )
        }
    }
}

const mapStateToProps = (state: any) => ({
    // state
    notes: state.notes,
});

const mapDispatchToProps = (dispatch: any) => ({
    getNotes: (req: any) => dispatch(MongoActions.getNotes(req.data, req.cb, "todo", 1, 25)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToDoList);
