import React, { Component } from 'react'
import { connect } from 'react-redux'
import MongoActions from "../../actions/MongoActions";
import NotePreview from "../layout/note/NotePreview";

interface IPageViewNotesProps {
    auth: any;
}

interface IPageViewNotesState {
}

class PageViewNotes extends Component<IPageViewNotesProps, IPageViewNotesState> {
    state: any = null;

    constructor(props: any) {
        super(props);
        this.state = {
            isAuthenticated: this.props.auth
        };
    }

    filterByTags: string[] = ["journal"];
    noteType: string = "journal";

    render() {
        return (
            <div>
                {/* Section Title */}
                <h3
                    className="readnotes"
                    id="todolist-title">
                    View All Journal Notes
                </h3>

                {/* Display Notes from Mongo API */}
                <NotePreview />
            </div>
        )
    }
}
const mapStateToProps = (state: any) => ({
    // state
    notes: state.notes,
});

const mapDispatchToProps = (dispatch: any) => ({
    getNotes: (req: any) => dispatch(MongoActions.getNotes(req.data, req.cb, "journal")),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageViewNotes);