// Import libraries
import React, { Component } from "react";

// Import components
import SideBar from "./elements/SideBar";
import ThemeSwitcher from "./elements/ThemeSwitcher";

// Import styling
import { MdPlaylistAdd, MdDoneAll, MdWeb } from "react-icons/md";
import logo from "../../assets/images/logo-yellow-1.png";

class SideBarMain extends Component {
    props: any;
    onChange: any;

    constructor(props: any) {
        super(props);
        this.onChange =
            (this.props.onChange ? this.props.onChange : null);
    }

    render() {
        return (
            <SideBar
                width={200}
                height={"100vh"}>
                <img
                    src={logo}
                    height="120"
                    alt="hivejournal logo" />
                <div className="nav-icons">
                    <ul className="nav-list">
                        <li className="nav-list-item">
                            <a href="/note/create" className="nav-button">
                                <MdPlaylistAdd
                                    size="25px" />
                            </a>
                        </li>
                        <li className="nav-list-item">
                            <a href="/note/read" className="nav-button">
                                <MdWeb
                                    size="25px" />
                            </a>
                        </li>
                        <li className="nav-list-item">
                            <a href="/todos" className="nav-button">
                                <MdDoneAll
                                    size="25px" />
                            </a>
                        </li>
                    </ul>
                </div>
                {/* <ToggleSwitch
                    onChange={this.onChange}
                    spanText="Enable quick-save"
                    onColor={'#4ec6fe'}
                    checked={this.quickSaveOn} /> */}
                <ThemeSwitcher />
            </SideBar>
        );
    }
}

export default SideBarMain;