// Import libraries
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import store from "./store/ConfigureStore";
import history from "./history";
import * as serviceWorker from "./ServiceWorker";

// Import components
import App from "./App";
import AppProvider from "./providers/AppProvider";

// Import styling & assets
import "bootstrap/dist/css/bootstrap.css";
import "semantic-ui-css/semantic.min.css";
import "./assets/styles/css/index.css";
import './assets/fonts/PremiumUltra26.ttf';

ReactDOM.render(
  <AppProvider>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </AppProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
