import * as React from "react";
import { Component } from "react";

class Callback extends Component {
    props: any;

    componentDidMount = () => {
        // Handle authentication if expected values are in the URL.
        if (/access_token|id_token|error/.test(this.props.location.hash)) {
            this.props.auth.handleAuthentication();
        } else {
            throw new Error("Invalid callback URL.");
        }
    };
    render() {
        return <div>create your notes here...</div>;
    }
}

export default Callback;
