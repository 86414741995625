import { ActionTypes } from "./ActionTypes";

export const registerRequest = (request: any) => {
    return request
};

export const createNotes = (appSession: any) => {
    return {
        type: ActionTypes.CREATE_NEW_NOTES,
        appSession: appSession,
    };
};

export const getNotes = (appSession: any) => {
    console.log(".:.AppSession:getNotes");
    return {
        type: ActionTypes.GET_NOTES,
        appSession: appSession,
    };
};

export const deleteNotes = (id: any) => {
    return {
        type: ActionTypes.REMOVE_NOTES,
        id: id,
    };
};

export const resetNotes = (appSession: any) => {
    return {
        type: ActionTypes.NOTES_SUCCESS,
        appSession: appSession,
    };
};
