export module theme {
    export const lightTheme: object = {
        colors: {
            background: '#FFF8F0',
            color: '#525252',
            offColor: '#ffffff',
            headlineColor: '#666666',
        },
        transition: '0.3s',
    };
    export const darkTheme: object = {
        colors: {
            background: '#252525',
            color: '#4ec6fe',
            offColor: '#cccccc',
            headlineColor: '#888888',
        },
        transition: '0.3s',
    };
}