import { MongoConstants } from "../constants/MongoConstants";
import { ApiConstants } from "../constants/ApiConstants";
import axios from "axios";
import Utils from "../utils/UtilsIndex";

let MongoActions = {
    createNote: function (data: any, cb: any) {
        return (dispatch: any) => {
            let api = ApiConstants.getMongoApi();
            axios({
                method: "POST",
                url:
                    api.url +
                    "service-http-notes-create/incoming_webhook/webhook-notes-create?secret=" +
                    api.secret,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            })
                .then((response) => {
                    dispatch(this.createNoteSuccess(response.data));
                    cb(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(this.createNoteError(error.response.data));
                    cb(error.response.data);
                });
        };
    },
    createNoteSuccess: function (response: any) {
        return {
            response,
            type: MongoConstants.CREATE_NOTE_SUCCESS,
        };
    },
    createNoteError: function (error: any) {
        return {
            error,
            type: MongoConstants.CREATE_NOTE_ERROR
        };
    },
    getNotes: function (data: any, cb: any, mainTag: string = "journal", pageNum: number = 1, pageSize: number = 10) {
        // TODO: change "noteType" to an array of strings

        return (dispatch: any) => {
            let api = ApiConstants.getMongoApi();
            let userId = localStorage.getItem("USER_ID");
            let _notes: [];

            axios({
                method: "GET",
                url:
                    api.url
                    + "service-http-notes-get-by-user/incoming_webhook/webhook_getUserNotes_filterByMainTags_v1?user_id="
                    + userId
                    + "&page_num=" + pageNum + "&page_size=" + pageSize + "&main_tags="
                    + mainTag
                    + "&secret="
                    + api.secret,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            })
                .then((response) => {
                    // Write the notes from the mongo API call to localstorage
                    if (response.data) {

                        // typeof string
                        let localStorageName: string = "USER_NOTES_MAINTAGS_" + (mainTag.toString().toUpperCase())

                        // array of note objects
                        _notes = response.data;
                        _notes.forEach((note: any) => {
                            note.note.note_orig_decrypted = Utils.decrypt(note.note.note_text);
                        });

                        localStorage.setItem(
                            localStorageName,
                            JSON.stringify(
                                _notes,
                            ),
                        );
                    }

                    dispatch(this.getNotesSuccess(response.data));
                    cb(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(this.getNotesError(error?.response?.data));
                    cb(error?.response?.data);
                });
        };
    },
    getNotesSuccess: function (response: any) {
        return {
            response,
            type: MongoConstants.GET_NOTES_SUCCESS,
        };
    },
    getNotesError: function (error: any) {
        return {
            error,
            type: MongoConstants.GET_NOTES_ERROR
        };
    },
    updateUserSettingAutoSave: function (data: any, cb: any) {
        return (dispatch: any) => {
            let api = ApiConstants.getMongoApi();
            axios({
                method: "POST",
                url:
                    api.url +
                    "service-http-users-settings-update-v1/incoming_webhook/webhook-http-users-settings-update-v1?secret=" +
                    api.secret,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            })
                .then((response) => {
                    dispatch(this.updateUserSettingAutoSaveSuccess(response.data));
                    cb(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(this.updateUserSettingAutoSaveError(error.response.data));
                    cb(error.response.data);
                });
        };
    },
    updateUserSettingAutoSaveSuccess: function (response: any) {
        return {
            response,
            type: MongoConstants.UPDATE_USER_SETTING_AUTOSAVE_SUCCESS
        };
    },
    updateUserSettingAutoSaveError: function (error: any) {
        return {
            error,
            type: MongoConstants.UPDATE_USER_SETTING_AUTOSAVE_ERROR
        };
    }
};
export default MongoActions;
