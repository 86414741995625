import React, { Component } from "react";

// import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";

// Import layout components
import TopBar from "./component/layout/TopBar";
import SideBarMain from "./component/layout/SideBarMain";
import PageCreateNote from "./component/pages/PageCreateNote";
import PageToDo from "./component/pages/PageToDo";
import PageMona from "./component/pages/PageMona";
import PageLocationFrontDoor from "./component/pages/PageLocationFrontDoor";
import PageViewNotes from "./component/pages/PageViewNotes";

// Import app functions
import Callback from "./component/auth/Callback";
import Auth from "./component/auth/Auth0";

// Import styling & assets
import "./assets/styles/css/App.css";

class App extends Component {
    auth: any;
    props: any;
    value: any;

    constructor(props: any) {
        super(props);
        this.auth = new Auth(this.props.history);
    }

    render() {
        return (
            <div className="App">
                <TopBar
                    auth={this.auth}
                    {...this.props} />
                <SideBarMain
                    // onChange={this.note.toggleSaveOnKeyEnter}
                    {...this.props} />


                {/* ***** UNAUTHENTICATED REDIRECTS ***** */}
                <div className="container">
                    <Route
                        path="/callback"
                        render={(props) => <Callback
                            auth={this.auth}
                            {...props} />} />
                    <Route
                        path="/"
                        render={(props) =>
                            this.auth.isAuthenticated() ?
                                (<Redirect to="/note/create" />) :
                                (<Redirect to="/note/create" />)} />
                    <Route
                        path="/note/read"
                        render={(props) =>
                            this.auth.isAuthenticated() ?
                                (<Redirect to="/note/read" />) :
                                (<Redirect to="/note/create" />)} />

                    <Route
                        path="/todos"
                        render={(props) =>
                            this.auth.isAuthenticated() ?
                                (<Redirect to="/todo/list" />) :
                                (<Redirect to="/note/create" />)} />

                    <Route
                        path="/door"
                        render={(props) =>
                            this.auth.isAuthenticated() ?
                                (<Redirect to="/door" />) :
                                (<Redirect to="/note/create" />)} />
                    <Route
                        path="/mona"
                        render={(props) =>
                            this.auth.isAuthenticated() ?
                                (<Redirect to="/mona" />) :
                                (<Redirect to="/note/create" />)} />

                </div>
                {/* ***** END UNAUTHENTICATED REDIRECTS ***** */}

                <Route
                    path="/note/create"
                    render={(props) => <PageCreateNote
                        auth={this.auth}
                        {...props} />}
                />
                <Route
                    path="/note/read"
                    render={(props) => <PageViewNotes
                        auth={this.auth}
                        {...props} />}
                />
                <Route
                    path="/todo/list"
                    render={(props) => <PageToDo
                        auth={this.auth}
                        {...props} />}
                />
                <Route
                    path="/mona"
                    render={(props) => <PageMona
                        auth={this.auth}
                        {...props} />}
                />
                <Route
                    path="/door"
                    render={(props) => <PageLocationFrontDoor
                        auth={this.auth}
                        {...props} />}
                />
            </div>
        );
    }
}

export default App;
