import * as CryptoJS from "crypto-js";
import { SettingConstants } from "../constants/SettingConstants";

let Utils = {
    encrypt: (str: any) => {
        let cipherText = CryptoJS.AES.encrypt(
            str,
            SettingConstants.SECRET_KEY
        ).toString();
        return cipherText;
    },
    decrypt: (str: any) => {
        let bytes = CryptoJS.AES.decrypt(str, SettingConstants.SECRET_KEY);
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        return originalText;
    },
    getWords: (text: any) => {
        let new_words = text.split(" ");
        return new_words.length;
    },
};

export default Utils;
