import React from 'react';
import { Checkbox } from '@material-ui/core';

let completed: boolean;
let noteText: string;
let starred: boolean;

export default function ToDoItem({ todo }: any) {
    completed = (todo.note.completed as boolean);
    starred = (todo.note.starred as boolean)
    noteText = (todo.note.note_orig_decrypted as string);

    return (
        <div className="todo-list-item">
            <label>
                <input type="checkbox" checked={completed} disabled />
                {/* <Checkbox className="todo-list-checkbox" checked={completed} disabled /> */}
            </label>
            {starred && <span>★</span>}
            <span>{noteText}</span>
        </div>
    )
}