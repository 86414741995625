import React, { Component } from 'react'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import MongoActions from "../../../actions/MongoActions";
import TextInfoCard from './TextInfoCard';

interface INotePreviewProps {
    getNotes: Function;
}

interface INotePreviewState {
}

class NotePreview extends Component<INotePreviewProps, INotePreviewState> {
    state: any = null;

    constructor(props: any) {
        super(props);
        this.state = {
            testText: "hello",
            mainTag: "journal",
            notes: [], // array of note objects,
            notesDecryptedText: [], // just decrypted text
        };
    }

    // This gets called when this component mounts
    componentDidMount() {
        // call the mongo API to get the latest notes
        let arg: any = {};
        this.props.getNotes({
            data: arg,
            cb: (res: any) => { },
            mainTag: this.state.mainTag
        });

        // typeof string
        let localStorageNotes = localStorage.getItem("USER_NOTES_MAINTAGS_" + (this.state.mainTag.toString().toUpperCase())) as any;

        // array of note objects
        let _notes: any = [];
        let _notesDecryptedText: any = [];

        if (localStorageNotes) {
            let note: any = {};
            let thisNote: any = {};
            let i = 0;
            for (note in localStorageNotes) {
                if (i < 60) {
                    // Append this note to the array of notes
                    thisNote = JSON.parse(localStorageNotes)[i];
                    _notes.push(thisNote);
                    _notesDecryptedText.push(thisNote?.note?.note_orig_decrypted)
                };
                i++;
            }
            this.setState({
                notes: _notes,
                notesDecryptedText: _notesDecryptedText
            });
        }
    }

    render() {
        const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
        return (
            this.state.notes.map((note: any) => {
                let tags: string = "#" + note.note.main_tag;
                if (note.note.note_tags) {
                    // If note tags exist, add to main tags
                    let tagsCount = note.note.note_tags.toString().split(",").length - 1;
                    for (let i = 0; i <= tagsCount; i++) {
                        tags += (" #" + note.note.note_tags.toString().split(",")[i])
                    }
                    // Remove duplicate tags
                    let tagsArr = tags.split(' ');
                    tags = tagsArr.filter(function (value, index, self) {
                        return self.indexOf(value) === index;
                    }).join(' ');
                };
                return (
                    <>
                        <TextInfoCard
                            overline={longEnUSFormatter.format(new Date(note.note.creation_timestamp))}
                            heading={tags}
                            body={note.note.note_orig_decrypted}
                        />
                        <IconButton className="note-delete-button">
                            <Close
                                height="5px"
                                width="5px" />
                        </IconButton>
                    </>)
            })
        )
    }
}

const mapStateToProps = (state: any) => ({
    notes: state.notes,
});

const mapDispatchToProps = (dispatch: any) => ({
    getNotes: (req: any) => dispatch(MongoActions.getNotes(req.data, req.cb, "journal", 1, 60)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotePreview);
