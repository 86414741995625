import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import RootReducer from "../reducers/ReducerIndex";

function saveToLocalStorage(state: any) {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("state", serializedState);
    } catch (err) {
        console.log(err);
    }
}

const middleware = [thunk];
const store = createStore(
    RootReducer,
    compose(
        applyMiddleware(...middleware)
    )
);

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
