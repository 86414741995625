import React from 'react';
import { Checkbox } from '@material-ui/core';
import imgSleep from 'assets/images/mona/sleep.png';
import imgMeditation from 'assets/images/mona/meditate.png';
import imgExercise from 'assets/images/mona/Quotefancy-5032599-3840x2160.jpg';
import imgFlower from 'assets/images/mona/flower-1.png';

let completed: boolean;
let noteText: string;
let starred: boolean;
let noteTags: Array<string>;

export default function MonaItem({ todo, diffSeconds, msNow, opacity, ageSeconds }: any) {
    completed = (todo.note.completed as boolean);
    starred = (todo.note.starred as boolean)
    noteText = (todo.note.note_orig_decrypted as string);
    noteTags = todo.note.note_tags

    // let imgMeditation = '../../../assets/meditate.png';
    // let imgSleep = '../../../assets/sleep.png';
    let noteImage = '';
    if (noteText === 'sleep') {
        noteImage = imgSleep;
    }
    if (noteText === 'exercise') {
        noteImage = imgExercise;
    }
    if (noteText === 'meditate') {
        noteImage = imgMeditation;
    }
    if (noteText === 'floss') {
        noteImage = imgFlower;
    }
    return (
        // <div className="todo-list-item" style={{ opacity: '100%' }}>
        <div className="todo-list-item" style={{
            opacity:`${opacity}%`,
            alignContent: 'center',
            alignItems: 'center',
            }}>
            {/* <label> */}
                {/* <input type="checkbox" checked={completed} disabled /> */}
                {/* <Checkbox className="todo-list-checkbox" checked={completed} disabled /> */}
            {/* </label> */}
            {/* {starred && <span>★</span>} */}
            {/* <span>{diffSeconds}</span> */}
            {/* <span>{ageSeconds}</span> */}
            <span>{opacity}%</span>
            <br />
            <img src={noteImage} height='200px'></img>
            {/* <img src={noteImage}></img> */}
            {/* <span>{noteText}</span> */}
            <span>{noteTags}</span>
        </div>
    )
}