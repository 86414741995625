import React, { Component } from "react";
import Switch from "react-switch";

class ToggleSwitch extends Component {
    state: any;
    props: any;
    className: string;
    checked: boolean;
    spanText: string;
    onColor: string;
    onChange: Function;

    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.className = this.props.className;
        this.checked = this.props.checked;
        this.spanText = this.props.spanText;
        this.onColor = this.props.onColor;
        this.state = { checked: (this.checked ? this.checked : false) };
        this.onChange = this.props.onChange;
    }

    handleChange(checked: boolean) {
        this.setState({ checked });
        this.checked = checked;
        if (this.onChange !== null) {
            this.onChange();
        }
    }

    render() {
        return (
            <label>
                <span>{this.spanText}</span>
                <Switch
                    className={this.className}
                    checked={this.state.checked}
                    onChange={this.handleChange}
                    onColor={this.onColor}
                    width={35}
                    height={18}
                    checkedIcon={false}
                    uncheckedIcon={false} />
            </label>
        );
    }
}

export default ToggleSwitch;