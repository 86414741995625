import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import { useN01TextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/n01';

interface ITextInfoCardProps {
    heading?: string;
    body?: string;
    overline?: string;
}

interface ITextInfoCardState {
}
class TextInfoCard extends Component<ITextInfoCardProps, ITextInfoCardState> {
    state: any = null;
    props: any;

    heading: string;
    body: string;
    overline: string;

    constructor(props: any) {
        super(props);
        this.overline = this.props.overline ? this.props.overline : null;
        this.body = this.props.body ? this.props.body : null;
        this.heading = this.props.heading ? this.props.heading : null;
        this.state = {
        };
    }

    render() {
        return (
            <Box
                width="26vw"
                className="text-info-card">
                <CardContent>
                    {/* Display tags */}
                    <div className="text-info-card-tags">
                        <span>
                            {this.heading}
                        </span>
                    </div>
                    {/* Display note body */}
                    <TextInfoContent
                        useStyles={useN01TextInfoContentStyles}
                        overline={this.overline}
                        body={this.body}
                    />
                </CardContent>
            </Box>
        );
    }
};

export default TextInfoCard;