import React, { Component } from 'react'
import QuoteRandom from 'component/layout/elements/QuoteRandom';
import MonaList from 'component/layout/mona/MonaList';
import PageCreateNote from 'component/pages/PageCreateNote';
import imgEmptyRoom from '../../assets/images/mona/empty-room.jpg';


interface IPageMonaProps {
    auth: any;
}

interface IPageMonaState {
}

class PageMona extends Component<IPageMonaProps, IPageMonaState> {
    state: any = null;
    interval: any;

    constructor(props: any) {
        super(props);
        this.state = {
            isAuthenticated: this.props.auth,
            time: Date.now(),
        };
    }

    filterByTags: string[] = ["todo"];
    noteType: string = "todo";
    starred: boolean = false;

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
      }
    render() {
        return (
            <div style={{alignItems: 'center',alignContent: 'center',alignSelf: 'center'}}>
                {/* <img src={imgEmptyRoom} height='400px' style={{position: 'absolute'}}></img> */}
                {/* <img src={imgEmptyRoom} height='800px' style={{position: 'absolute'}}></img> */}
                <img src={imgEmptyRoom} style={{
                    // marginLeft: '-600px',
                    // marginLeft: '-600px',
                    marginLeft: '-50vw',
                    position: 'absolute',
                    // height: '700px',
                    height: '100vh',
                    }}></img>
                {/* <span>{this.state.time}</span> */}
                {/* <br /> */}
                {/* Display a random quote */}
                {/* <QuoteRandom filterByTags={this.filterByTags} /> */}

                {/* Section Title */}
                <h3
                    className="MonaList"
                    id="MonaList-title">
                    36 hour fade
                </h3>

                {/* Display ToDos from Mongo API */}
                {/* <MonaList /> */}

                {/* Display Starred ToDo Items */}
                <div style={{
                    // marginTop:'-230px'
                    // marginTop:'225px',
                    marginTop: '20vh',
                    // height:'200px'
                    height: '40vh',
                    marginLeft: '-15vw',
                    width: '30vw',
                    }}>
                    <MonaList showStarredOnly={true} msNow={this.state.time} />
                </div>

                {/* Section Title */}
                {/* <h3
                    className="MonaList"
                    id="MonaList-title">
                    Tomorrow (Woman/Man)
                </h3> */}

                {/* <hr /> */}
                {/* Display Un-Starred ToDo Items */}
                {/* <MonaList showUnStarredOnly={true} /> */}

                {/* Display the "Create Note" Form */}
                {/* <PageCreateNote noteType={this.noteType} /> */}

            </div>
        )
    }
}
export default PageMona;