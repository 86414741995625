import React, { createContext, useState, useEffect } from "react";
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, theme } from '../assets/styles/ts';

export const AppContext = createContext(this);

//const themeImport: any = theme;

const AppProvider = ({ children }: any) => {
    const [themeMode, setThemeMode]: any = useState(localStorage.getItem("theme") || "darkTheme");

    const themeStyles: any = theme;

    useEffect(() => {
        localStorage.setItem("theme", themeMode);
    }, [themeMode]);

    const toggleTheme = () => {
        setThemeMode((prevState: any) => {
            if (prevState === "lightTheme") {
                return "darkTheme";
            }
            return "lightTheme";
        });
    };

    const value = { themeMode, toggleTheme };
    const customTheme: any | null = themeStyles[themeMode] ? themeStyles[themeMode] : null;

    return (
        <AppContext.Provider value={Object(value)}>
            <ThemeProvider theme={customTheme}>
                <GlobalStyles />
                {children}
            </ThemeProvider>
        </AppContext.Provider>
    );
};

export default AppProvider;