import "../../../assets/styles/css/App.css";
import React from "react";

const SideBar = ({ width, height, children }: any): any => {
  const [xPosition, setX] = React.useState(-width);

  const toggleMenu = () => {
    if (xPosition < 0) {
      setX(0);
    } else {
      setX(-width);
    }
  };

  React.useEffect(() => {
    setX(-width);
  }, [width]);

  return (
    <React.Fragment>
      {/* Primary Sidebar */}
      <div
        className="side-bar"
        style={{
          transform: `translatex(${xPosition}px)`,
          width: width,
          height: height,
          overflowX: `visible`
        }}>
        {/* Sidebar Toggle Button */}
        <div
          onClick={() => toggleMenu()}
          className="toggle-menu"
          style={{
            transform: `translate(${width}px, 20vh)`,
            margin: `0px`
          }} />
        {/* Sidebar Children */}
        <div className="content">
          {children}
        </div>
        {/* Sidebar Toggle Div */}
        <div
          className="side-bar2"
          onClick={() => toggleMenu()}
          style={{
            transform: `translatex(${xPosition}px)`,
            width: width,
            height: `65vmin`,
            overflowY: `hidden`
          }} />
      </div>
      {/* Main Div Sidebar Toggle */}
      {/* <div
        className="side-bar-close-div"
        onClick={() => toggleMenu()} /> */}
    </React.Fragment >
  );
};

export default SideBar;